<template>
  <VeeForm
    :ref="`addResourceCapacityTime${id}`"
    @submit="submitForm"
    v-slot="{ errors }"
    class="form"
    novalidate
  >
    <ul class="clebex-item-section">
      <li
        class="clebex-item-section-item"
        :class="{ 'has-error': errors.date_from || errors.date_to }"
      >
        <span class="error-message" v-if="errors.date_from || errors.date_to">
          {{ errors.date_from || errors.date_to }}
        </span>
        <div class="clebex-item-content-wrapper">
          <ul class="clebex-item-content-range">
            <li class="clebex-item-range from">
              <button
                type="button"
                @click.prevent="
                  showDateFrom = !showDateFrom;
                  showDateTo = false;
                "
                class="clebex-item-range-cta"
              >
                {{
                  selectedDateFromParsed
                    ? selectedDateFromParsed
                    : displayLabelName(
                        "resources",
                        "resource-capacity-type-time-types",
                        "select-date"
                      )
                }}
              </button>
            </li>
            <li class="clebex-item-range separator">
              <icon icon="#cx-app1-arrow-right-12x12" width="12" height="12" />
            </li>
            <li class="clebex-item-range to">
              <button
                type="button"
                @click.prevent="
                  showDateTo = !showDateTo;
                  showDateFrom = false;
                "
                class="clebex-item-range-cta"
              >
                {{
                  selectedDateToParsed
                    ? selectedDateToParsed
                    : displayLabelName(
                        "resources",
                        "resource-capacity-type-time-types",
                        "select-date"
                      )
                }}
              </button>
            </li>
          </ul>
        </div>
        <Field
          name="date_from"
          as="input"
          type="hidden"
          rules="required"
          v-model="selectedDateFrom"
          :disabled="submitting"
        />
        <Field
          name="date_to"
          as="input"
          type="hidden"
          rules="required"
          v-model="selectedDateTo"
          :disabled="submitting"
        />
      </li>
      <li class="clebex-item-section-item" v-if="showDateFrom">
        <div class="clebex-item-content-wrapper">
          <select-date
            :selectDateFn="selectDateFrom"
            :selected-date="selectedDateFrom"
            :date-to-watch="selectedDateFrom"
            :show-date-picker="showDateFrom"
            date-format="YYYY-MM-DD"
            display-date-format="D MMMM YYYY"
          />
        </div>
      </li>
      <li class="clebex-item-section-item" v-if="showDateTo">
        <div class="clebex-item-content-wrapper">
          <select-date
            :selectDateFn="selectDateTo"
            :selected-date="selectedDateTo"
            :date-to-watch="selectedDateTo"
            :show-date-picker="showDateTo"
            date-format="YYYY-MM-DD"
            display-date-format="D MMMM YYYY"
          />
        </div>
      </li>
      <li
        class="clebex-item-section-item"
        :class="{ 'has-error': errors.capacity }"
      >
        <span class="error-message" v-if="errors.capacity">
          {{ errors.capacity }}
        </span>
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" :for="`capacity${id}`"
              >{{
                displayLabelName(
                  "resources",
                  "resource-capacity-type-time-types",
                  "capacity"
                )
              }}
              <span>*</span></label
            >
            <Field
              name="capacity"
              :id="`capacity${id}`"
              as="input"
              type="text"
              rules="required"
              v-model="capacity"
              :disabled="submitting"
            />
          </div>
        </div>
      </li>
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl">
            <dt class="clebex-item-dt">
              <button type="submit">
                <icon icon="#cx-app1-checkmark" />
              </button>
            </dt>
            <dd class="clebex-item-dd">
              <button
                type="button"
                @click="initial ? deleteItemFn(id) : (show = true)"
              >
                <icon icon="#cx-app1-delete-02-14x14" />
              </button>
            </dd>
          </dl>
        </div>
      </li>
    </ul>
  </VeeForm>
  <screen-modal
    v-if="show"
    class="confirm-modal delete-modal"
    type="success"
    :confirm-action="deleteDateTimeForm"
    :confirm-button-label="
      displayLabelName(
        'resources',
        'resource-capacity-type-time-types',
        'delete-confirm-action'
      )
    "
    :cancel-button-label="
      displayLabelName(
        'resources',
        'resource-capacity-type-time-types',
        'delete-cancel-action'
      )
    "
    :show="show"
    @close="show = false"
  >
    <h3 class="modal-title">
      {{
        displayLabelName(
          "resources",
          "resource-capacity-type-time-types",
          "delete-title"
        )
      }}
    </h3>
    <p class="text">
      {{
        displayLabelName(
          "resources",
          "resource-capacity-type-time-types",
          "delete-action-text"
        )
      }}
      <br />
      {{
        displayLabelName(
          "resources",
          "resource-capacity-type-time-types",
          "delete-action-question"
        )
      }}
    </p>
  </screen-modal>
</template>

<script>
import { defineAsyncComponent } from "vue";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { mapActions, mapState } from "vuex";
import dayjs from "dayjs";
import { errorHandler } from "@/services/error-handler";

export default {
  name: "ResourceCapacityTypeTimeForm",
  components: {
    SelectDate: defineAsyncComponent(() =>
      import("@/components/global/SelectDate")
    )
  },
  data() {
    return {
      submitting: false,
      show: false,
      capacity: "",
      showDateFrom: false,
      showDateTo: false,
      selectedDateFrom: false,
      selectedDateTo: false,
      selectedDateFromParsed: null,
      selectedDateToParsed: null
    };
  },
  watch: {
    preselectedData: {
      immediate: true,
      handler() {
        if (this.preselectedData) {
          const { date_from, date_to, capacity } = this.preselectedData;
          this.selectedDateFrom = dayjs(date_from).format("YYYY-MM-DD");
          this.selectedDateFromParsed = dayjs(date_from).format("D MMMM YYYY");
          this.selectedDateTo = dayjs(date_to).format("YYYY-MM-DD");
          this.selectedDateToParsed = dayjs(date_to).format("D MMMM YYYY");
          this.capacity = capacity;
        }
      }
    }
  },
  computed: {
    ...mapState("resource", ["resource"])
  },
  methods: {
    ...mapActions("resource", ["getResourceCapacityTypeDateData"]),
    submitForm(values) {
      this.submitting = true;
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      let method = "put";
      if (this.initial) {
        method = "post";
      }

      let capacity_id = "";
      if (this.preselectedData && this.preselectedData.id) {
        capacity_id = `/${this.preselectedData.id}`;
      }

      if (values) {
        httpServiceAuth({
          method,
          url: `${apiEndpoints.company.resources}/${this.resource.data.id}/resourcedatecapacities${capacity_id}`,
          data: values
        })
          .then(() => {
            this.getResourceCapacityTypeDateData(this.resource.data.id);
            if (this.initial) {
              this.deleteItemFn(this.id);
            }
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response, this.findElement());
            }
          })
          .finally(() => {
            this.submitting = false;
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
          });
      }
    },
    selectDateFrom(date, displayDate) {
      this.selectedDateFrom = date;
      this.selectedDateFromParsed = displayDate;
    },
    selectDateTo(date, displayDate) {
      this.selectedDateTo = date;
      this.selectedDateToParsed = displayDate;
    },
    clearValues() {
      this.selectedDateFromParsed = null;
      this.selectedDateFrom = false;
      this.selectedDateToParsed = null;
      this.selectedDateTo = false;
    },
    deleteDateTimeForm() {
      this.submitting = true;
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .delete(
          `${apiEndpoints.company.resources}/${this.resource.data.id}/resourcedatecapacities/${this.preselectedData.id}`
        )
        .then(() => {
          this.getResourceCapacityTypeDateData(this.resource.data.id);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.submitting = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    }
  },
  props: {
    id: {
      required: true,
      type: [String, Number]
    },
    initial: {
      required: false,
      type: Boolean
    },
    deleteItemFn: {
      required: true,
      type: Function
    },
    preselectedData: {
      required: false,
      type: Object
    }
  }
};
</script>
